import styled from "styled-components";

/**
 * @todo replace this with ButtonComponent as its deprecated now.
 */

export const Button = styled.button.attrs({
  className: "text-black font-bold py-2 rounded-lg",
})`
  background-color: #c6ff5c;
`;

export const Label = styled.label.attrs({
  className: "block text-sm ",
})`
  color: #AEAEAE;
`;
